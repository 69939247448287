<template>
  <div>
    <b-tabs class="shadow p-2">
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="programs.length < 1"
          class="text-center"
        >
          No programs found under your organizations!
        </b-card>
        <section class="grid-view">
          <!-- <b-card
        v-for="(program, i) in programs"
        :key="i"
        :title="program.title"
        class="ecommerce-card"
        img-alt="card img"
        img-top
        no-body
      >
        <div
          class="px-2 py-1 bg-primary"
        >
          <b-card-title class="text-white">
            {{ program.title }}
          </b-card-title>
          <b-card-sub-title>
            <span class="text-light">{{ program.type }}</span>
          </b-card-sub-title>
        </div>
        <b-card-body class="p-2">
          <b-card-text>
            Description: <br> <em>{{ program.description }}</em>
            <hr>
            Begin date: {{ program.begin_date }}
            <br> Capacity: {{ program.capacity }}
            <br> Industry: {{ program.industry }}
          </b-card-text>
        </b-card-body>
        Product Actions
        <div class="item-options text-center">
          <b-link
            :to="`/mentor-portal/programs/rounds/${program.id}/${program.programs_partnerstables[0].id}`"
            class="btn btn-wishlist btn-light"
            variant="light"
          >
            <span>Applications</span>
          </b-link>
          <b-link
            :to="`/mentor-portal/programs/select/${program.id}/${program.programs_partnerstables[0].id}`"
            class="btn btn-primary btn-cart"
          >
            <span>Open</span>
          </b-link>
        </div>
      </b-card> -->
          <b-card
            v-for="(program, i) in programs"
            :key="i"
            header-tag="header"
            class="ecommerce-card"
          >
            <b-img
              class="rounded"
              src="https://picsum.photos/600/300/?image=25"
              alt="image"
              img-top
              style="width: 100%; position: relative;"
            />
            <b-badge
              style="position: absolute; top: 95px; right: 25px;color: black;"
              variant="light"
            >
              Event Type
            </b-badge>
            <b-badge
              style="position: absolute; top: 125px; right: 25px;color: black;"
              variant="light"
            >
              Free
            </b-badge>
            <b-badge
              style="position: absolute; top: 95px; left: 25px;color: black;"
              variant="light"
            >
              E
            </b-badge>
            <template #header>
              <div class="d-flex align-items-center">
                <b-img
                  rounded
                  alt="Rounded image"
                  src="https://shorturl.at/kxFUX"
                  style="height: 30px; width: 30px; margin-right: 10px;"
                />
                <b-card-text> <strong>Startup Name</strong></b-card-text>
              </div>
            </template>
            <!-- <div
          class="px-2 py-1 bg-primary"
        >
          <b-card-title class="text-white">
            {{ program.title }}
          </b-card-title>
          <b-card-sub-title>
            <span class="text-light">{{ program.type }}</span>
          </b-card-sub-title>
        </div> -->
            <div class="mt-2 mb-1">
              <div>
                <b-card-text style="font-size: large; margin-top: 15px; margin-bottom: 5px;">
                  <strong> {{ program.title }} </strong>
                </b-card-text>
              </div>
              <div class="d-flex align-items-baseline justify-content-between">
                <b-card-text><strong>Date : {{ beginDate(program.begin_date) }}</strong></b-card-text>
                <b-badge class="badge badge-light-success">
                  Online
                </b-badge>
              </div>
              <b-card-sub-title class="mt-1">Some quick example text to build on the card title and make up the bulk of the card's content.
              </b-card-sub-title>
            </div>
            <!-- <b-card-body class="p-2">
          <b-card-text>
            Description: <br> <em>{{ program.description }}</em>
            <hr>
            Begin date: {{ program.begin_date }}
            <br> Capacity: {{ program.capacity }}
            <br> Industry: {{ program.industry }}
          </b-card-text>
        </b-card-body> -->
            <!-- Product Actions -->
            <div class="item-options text-center d-flex">
              <!-- :to="`/mentor-portal/programs/${program.id}`" -->
              <b-link
                class="btn btn-wishlist btn-light mr-1 rounded"
                variant="light"
                @click="$bvModal.show('view-event-modal')"
              >
                <span>View Details</span>
              </b-link>
              <b-link
                v-if="program.users_associationtables[0].status.toLowerCase() === 'invited'"
                class="btn btn-wishlist btn-primary ml-1 rounded"
                variant="light"
                @click="selectedIndex = i; $bvModal.show('respond-modal')"
              >
                <span>Respond</span>
              </b-link>
              <b-link
                v-else
                :class="{
                  'btn-success': program.users_associationtables[0].status.toLowerCase() === 'accept',
                  'btn-danger': program.users_associationtables[0].status.toLowerCase() === 'reject'
                }"
                class="btn btn-wishlist ml-1 rounded"
                disabled
                variant="light"
                @click="selectedIndex = i; $bvModal.show('respond-modal')"
              >
                <span>{{ program.users_associationtables[0].status + 'ed' }}</span>
              </b-link>
            </div>
          </b-card>
          <b-modal
            id="respond-modal"
            cancel-title="Reject"
            ok-title="Accept"
            title="Respond to Invitation"
            @cancel="updateResponse(false)"
            @ok="updateResponse(true)"
          >
            <span
              v-if="selectedIndex !== null"
            >
              Would you like to join <strong class="text-primary">{{ programs[selectedIndex].title }}</strong> as a <strong
                class="text-primary"
              >{{ programs[selectedIndex].users_associationtables[0].role }}</strong>?
            </span>
          </b-modal>
        </section>
      </b-overlay>
    </b-tabs>
    <b-modal
      id="view-event-modal"
      title="Event Title"
      hide-footer
      size="lg"
    >
      <b-img
        src="https://picsum.photos/600/300/?image=25"
        style="width: 100%;"
      />
      <b-table
        stacked
        bordered
        :items="eventItems"
      >
        <template #cell(url)="data">
          <b-link
            :href="prependHttps(data.item.url)"
            target="_blank"
          >{{ data.item.url }}
          </b-link>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardText, BLink, BOverlay, BImg, BBadge, BModal, BTable, BTabs,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BTabs,
    BImg,
    BBadge,
    BModal,
    BTable,
    // BCardFooter,
    BCardText,
    // BCardBody,
    // BCardTitle,
    // BCardSubTitle,
    BOverlay,
    BLink,
  },
  data() {
    return {
      selectedIndex: null,
      eventItems: [
        {
          'Organized By': 'xyz', 'Event Title': 'Startup India', 'Event Type': 'Online', 'Description ': 'this is a description this is a description this is a description this is a description this is a description this is a description this is a description', 'Start Date & Time': '12 sept 2023', 'End Date & Time': '24 sept 2023',
        },
      ],
      programs: [],
      userID: getUserData().id,
      mutationLoading: false,
    }
  },
  methods: {
    beginDate(date) {
      const dateNew = new Date(date)
      const options = { day: 'numeric', month: 'long', year: 'numeric' }
      const formattedDate = dateNew.toLocaleDateString('en-US', options)
      return formattedDate
    },
    updateResponse(status) {
      this.mutationLoading = true
      const stmt = [`
            update_users_associationtable_by_pk(pk_columns: {id: ${this.programs[this.selectedIndex].users_associationtables[0].id}}, _set: {status: "${status ? 'Accept' : 'Reject'}"}) {
              id
              status
            }`]
      // Update mentor table if accepted
      if (status === true) {
        stmt.push(`
            insert_programs_partnerstable_one(object: {user_id: ${this.userID}, program_id: ${this.programs[this.selectedIndex].id}, designation: ${this.programs[this.selectedIndex].users_associationtables[0].designation}, is_jury: ${this.programs[this.selectedIndex].users_associationtables[0].is_jury} }) {
              id
            }`)
      }
      this.$apollo.mutate({
        mutation: gql`mutation {${stmt.join('')}}`,
        update: (store, { data: { update_users_associationtable_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_users_associationtable_by_pk.id ? 'Your response has been recorded' : 'Failed to submit response',
              icon: update_users_associationtable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_users_associationtable_by_pk.id ? 'success' : 'danger',
            },
          })
          this.$apollo.queries.programs.refetch()
          window.location.reload()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    programs: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
        {
            programs_basicinfo(where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, users_associationtables: {user_id: {_eq: ${this.userID} }}}) {
              begin_date
              capacity
              id
              industry
              description
              type
              title
              status
              users_organizationtable {
                title
                url
              }
              users_associationtables(where: {user_id: {_eq: ${this.userID} }}) {
                id
                designation
                status
                role
              }
            }
          }`
      },
      update: data => {
        data.programs_basicinfo.forEach(program => {
          // eslint-disable-next-line no-unused-expressions,no-param-reassign
          program.users_associationtables[0].readonly = !(program.users_associationtables[0].status.toLowerCase() === 'invited')
        })
        return data.programs_basicinfo
      },
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
